import axios from "axios";
import api from "../../../Repository/Interceptor";

export const SendAuthCodeEmail = async (email, isLanguage) => {
  try {
    const resp = await api.get("/user/email/sendauthcode", {
      params: {
        email: email,
        lang: isLanguage
      },
    });

    console.log("이메일 인증코드", resp);
    return {
      data: resp,
      msg: "Success",
    };
  } catch (error) {
    console.log("이메일 인증코드 전송에러", error);
    return {
      data: error,
      msg: "Fail",
    };
  }
};
